.edit-btn {
  width: 95px;
  height: 32px;
  background: #838383;
  border-radius: 4px;
  border-style: none;
  margin-top: -10px;

  font-family: Roboto, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #ffffff;
}

.block-title {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;

  color: #000000;
}

.overview-list-title {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 27px;

  color: #000000;
}

.overview-list {
  margin-top: 15px;
}

.overview-list li {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;

  color: #000000;
}
