.ui-document {
    position: relative;
}

.ui-document:hover > .actions {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
}

.ui-document > .actions {
    position: absolute;
    top: 100%;
    left: 50%;

    z-index: 1;

    padding: 0.66em;

    width: 120px;
    margin-left: -60px;

    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 5px;

    /* Hidden state */
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.ui-document > .actions button {
    margin: 0.1em auto;
    width: 100%;
}

.ui-document > .preview-container {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 80px;
}

.ui-document > .preview-container svg {
    height: 33%;
}

.ui-document > .info > .document-name {
    font-weight: 500;
}

.ui-document > .info > .document-status {
    font-size: 0.9em;
    text-align: left;
    opacity: 0.75;
}

.ui-document > .info > .document-status.approved {
    color: var(--green);
}

.ui-document > .info > .document-status.rejected {
    color: var(--danger);
}

.ui-document > .info > .document-status.signed {
    color: var(--info);
}

.ui-dropdown-no-arrow::after {
    display: none !important;
}

.ui-form-middle {
    display: flex;
    align-items: center;
    /* margin-bottom: 1rem; */
}

.ui-form-middle > *:first-of-type {
    width: 100%;
    margin-right: 0.25rem ;
}

.ui-form-middle > *:last-of-type {
    width: 100%;
    margin-left: 0.25rem;
}

.ui-keyvalue-container > .pair {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ui-keyvalue-container > .container-title {
    margin-bottom: 0.5rem;
    font-weight: 600;
}

.ui-keyvalue-container > .pair > div:last-of-type {
    font-weight: 500;
    text-align: right;
}

.ui-dual-listbox {
    margin: 1rem 0;
    min-height: 250px;
}

/* Approval request */
.ui-approval-request {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.ui-approval-request > .left {
    width: 80%;

    margin-right: 1em;
}

.ui-approval-request > .left > .request-name {
    font-weight: 500;
    font-size: 1.1em;
}

.ui-approval-request > .right {
    display: flex;
    flex-direction: column;

    width: 20%;
}

.ui-approval-request > .right > button {
    margin: 0.25em 0;
}

.ui-table-filters {

}

.ui-table-filters > .fields {
    display: flex;
    justify-content: space-between;

    margin: 2em 0;
}

.ui-table-filters > .fields > div {
    display: flex;
    align-items: center;
}

.btn:disabled {
    cursor: default;
}

/* TODO @hack */
.ui-no-mb-if-last:last-of-type {
    margin-bottom: 0 !important;
}

.overlay, .overlay-fixed{
    position: fixed;
    background-color: #fff;
    overflow: hidden;
    z-index: 1024;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
.overlay-fixed{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.jodit-container:not(.jodit_inline){
    border: 1px solid rgba(0, 0, 0, 0.23);
}
.jodit-container:not(.jodit_inline):focus-within{
    border: 2px solid #17C191;
}

.jodit-container ul, ol {
    padding-left: 25px;
}

.date-picker{
    border: 1px solid red;
}

.shake {
    animation: shake-animation 4.72s ease infinite;
    transform-origin: 50% 50%;
}

@keyframes shake-animation {
    0% { transform:translate(0,0) }
    1.78571% { transform:translate(5px,0) }
    3.57143% { transform:translate(0,0) }
    5.35714% { transform:translate(5px,0) }
    7.14286% { transform:translate(0,0) }
    8.92857% { transform:translate(5px,0) }
    10.71429% { transform:translate(0,0) }
    100% { transform:translate(0,0) }
}

.overlay{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 1024;
}

.screen-container{
    padding: 0;
    border-radius: 2rem;
    box-shadow: 0 0 7px #b0b0b0;
}
.success-status{
    font-size: 36px;
}
.success-header{
    position: relative;
    background: -webkit-linear-gradient(45deg, #019871, #a0ebcf);
    padding: 50px;
}
.success-body{
    position: relative;
    z-index: 1025;
    padding: 32px;
}
.success-body > .btn {
    background-color: #019871;
    color: white;
    font-size: 18px;
    padding: 10px 40px;
}
.bubbles{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.bubbles > li{
    position: absolute;
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.15);/* fade(green, 75%);*/
    bottom: -160px;
    -webkit-animation: square 20s infinite;
    animation:         square 20s infinite;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
}
.bubbles > li:nth-child(1){
    left: 10%;
}
.bubbles > li:nth-child(2){
    left: 20%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
    animation-duration: 17s;
}
.bubbles > li:nth-child(3){
    left: 25%;
    animation-delay: 3s;
}
.bubbles > li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-duration: 22s;
    background-color: rgba(white, 0.3); /* fade(white, 25%); */
}
.bubbles > li:nth-child(5){
    left: 70%;
}
.bubbles > li:nth-child(6){
    left: 80%;
    width: 120px;
    height: 120px;
    animation-delay: 1s;
    background-color: rgba(white, 0.2); /* fade(white, 20%); */
}
.bubbles > li:nth-child(7){
    left: 32%;
    width: 160px;
    height: 160px;
    animation-delay: 6s;
}
.bubbles > li:nth-child(8){
    left: 55%;
    width: 20px;
    height: 20px;
    animation-delay: 15s;
    animation-duration: 40s;
}
.bubbles > li:nth-child(9){
    left: 25%;
    width: 10px;
    height: 10px;
    animation-delay: 0s;
    animation-duration: 40s;
    background-color: rgba(white, 0.3); /*fade(white, 30%);*/
}
.bubbles > li:nth-child(10){
    left: 90%;
    width: 160px;
    height: 160px;
    animation-delay: 11s;
}

@-webkit-keyframes square {
    0%   { transform: translateY(0); }
    100% { transform: translateY(-500px) rotate(600deg); }
}
@keyframes square {
    0%   { transform: translateY(0); }
    100% { transform: translateY(-500px) rotate(600deg); }
}

.search-btn{
    background: #1BC5BD;
    color: white;
}

.mw-100{
    max-width: 100px;
}

.mw-120{
    max-width: 120px;
}

.mw-175{
    max-width: 175px;
}

.mw-150{
    max-width: 150px;
}

.mw-200{
    max-width: 200px;
}

.mw-250{
    max-width: 250px;
}

.limit-text{
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-title{
    font-size: 1.5rem;
}

.section-header{
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 20px;
    font-style: normal;
    text-decoration: none !important;
}

.table-responsive > th, td {
    max-width: 170px;
}

.phone-input {
    height: 52px;
    color: black;
}

.phone-input:focus  {
    border-color: #17C191!important;
    border-width: 2px!important;
}

.phone-input.error {
    color: #f018a6;
    border-color: #f018a6!important;
}