.cancel-btn {
    background: #E5EAEE;
    color: #8F96A1;
    border-radius: 0.42rem;
    border-color: #E5EAEE;
    border-style: none;
}
.submit-btn {
    background: #17C191;
    color: #FFFFFF;
    border-radius: 0.42rem;
    border-color: #17C191;
    border-style: none;
}
.form-header, .form-icon{
    color: #17C191;
}
