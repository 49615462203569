#profile-page > .panels-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    width: 100%;
}

#profile-page > .panels-container > .left-panel {
    width: 24%;
}

#profile-page > .panels-container > .right-panel {
    width: 75%;
}

#profile-page > .panels-container > .right-panel > .main-panel-content {
    box-sizing: border-box;
    padding: 1em 1.5em;
}

#profile-page > .panels-container .panel-content {
    box-sizing: border-box;
    padding: 1em 0;
}

#profile-page > .panels-container > .left-panel .menu-link {
    display: block;

    padding: 0.8em 1.6em;
    width: 100%;

    font-weight: 500;
}

#profile-page > .panels-container > .left-panel .menu-link:hover {
    background-color: hsl(0, 0%, 98%);
}

#profile-page > .panels-container > .left-panel .menu-link:hover > .menu-text {
    color: hsl(0, 0%, 15%) !important;
}

#profile-page > .panels-container > .left-panel .menu-link .menu-icon {
    margin-right: 1em;
}
