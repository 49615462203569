// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

// Default Layout themes
@import './_metronic/_assets/sass/themes/layout/header/base/light.scss';
@import './_metronic/_assets/sass/themes/layout/header/menu/light.scss';
// @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// @import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
@import './_metronic/_assets/sass/themes/layout/header/menu/light.scss';
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
// .brand-dark {
//   @import './_metronic/_assets/sass/themes/layout/brand/dark.scss';
// }
// Light
.brand-light {
  @import './_metronic/_assets/sass/themes/layout/brand/light.scss';
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
@import './_metronic/_assets/sass/themes/layout/aside/light.scss';

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

.color-white {
  color: white,
}

.f-size-16 {
  font-size: 16px
}

// body, html {
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
// }

// .aside-minimize:not(.aside-minimize-hover) {
//   .white-rectangle {
//     visibility: hidden !important;
//   }

//   .csp-input {
//     color: transparent;
//     border: none !important;

//     .MuiInputBase-root {
//       background: transparent;
//       border: none;
//       border: none !important;
//     };

//     .MuiSelect-root {
//       background: 'transparent';
//       color: transparent;
//       font-weight: 500;
//       font-size: 16;
//       border: none !important;
//     }

//     fieldset {
//       border: none !important;
//     }

//     // '& .Mui-focused fieldset': {
//     //   color: 'white',
//     //   border: '1px solid #404040 !important'
//     // },

//     // '& MuiInputBase-root:hover': {
//     //   border: '1px solid #404040 !important'
//     // }
//   }
// }

.data-table-row {
  display: block;

  color: var(--gray-dark);
}

.data-table-row:hover {
  background: #f1f1f1 !important;
}

a.menu-link {
  display: flex !important;
  justify-content: center;
}

  .reset-expansion-style {
    padding: 0!important;
  }

  .subtable {
    thead {
      visibility: collapse;
    }
    table {
      margin-bottom: 0;
    }
    .reset-expansion-style {
      display: none;
    }
  }

.link {
  transition: .2s ease-in-out;
}

.link:hover {
  color: #0073e9;
  cursor: pointer;
}

.menu-link {
  svg {
    fill: #B5B5C3;
    transition: .2s ease-in-out;
  }

  &.active, &:hover {
    svg {
      fill: #3699FF;
    }
  }
}

.menu-icon-minimized {
  display: flex;
  justify-content: center;
}

.bg-light-hover {
  &:hover {
    background-color: #F3F6F9;
  }
}

.no-after {
  &::after {
    display: none !important;
  }
}

body {
  overflow: unset !important;
  padding-right: 0 !important;
}

.btn-circle-40 {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center !important;
  align-items: center !important
}