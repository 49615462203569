.ui-address-container {

}

.ui-address-container > .search-container {
    display: flex;
    align-items: center;
}

.ui-address-container > .search-container > div:first-of-type {
    width: 90%;
}

.ui-address-container .suggestions-container > .suggestion {
    cursor: pointer;
}

.ui-address-container .suggestions-container > .suggestion > .title {
    font-weight: 500;
}

.ui-address-container .location-qualifiers-container > .qualifier {
    display: flex;
    align-items: center;
}

.ui-address-container .location-qualifiers-container > .qualifier {

}
.qualifier-btn {
    background: #E5EAEE;
    color: black;
}