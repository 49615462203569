#document-modal {
    width: 66vw;
    min-width: 600px;
    max-width: none !important;
}

#document-modal .modal-body {
    display: flex;
}

#document-modal .document-container {
    width: 66%;

    box-sizing: border-box;
    padding: 1em;
}

#document-modal .document-container > img {
    width: 100%;
    max-height: 66vh;
    object-fit: contain;
}

#document-modal .right-container {
    width: 33%;

    box-sizing: border-box;
    padding: 1em;
}

#document-modal .right-container.disabled {
    opacity: 0.5;
    pointer-events: none;
}

#document-modal .right-container .spacer {
    margin: 3em auto;

    width: 80%;
    height: 1px;

    background-color: hsl(0, 0%, 92%);
}

#document-modal .right-container .status-buttons-container {
    display: flex;
    flex-wrap: wrap;
}

#document-modal .right-container .status-buttons-container > button {
    margin: 0.5em;
    width: 47%;
}
