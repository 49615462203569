#beneficial-owners-wizard-page {
    
}

#beneficial-owners-wizard-page > .route-breadcrumbs {
    display: flex;
    align-items: center;

    margin-bottom: 1rem;
}

#beneficial-owners-wizard-page > .route-breadcrumbs > .spacer {
    margin: 0 1rem;
}

#beneficial-owners-wizard-page > .route-breadcrumbs > .spacer:first-child {
    display: none;
}

#beneficial-owners-wizard-page > .route-breadcrumbs .item:last-child {
    cursor: not-allowed !important;
}

#beneficial-owners-wizard-page > .shareholders > .shareholder.clickable {
    cursor: pointer;
    
    transition: 0.15s;
}

#beneficial-owners-wizard-page > .shareholders > .shareholder.clickable:hover {
    background-color: var(--secondary);
}

#beneficial-owners-wizard-page > .add-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
}

#beneficial-owners-wizard-page > .add-buttons .btn {
    margin: 0 0.5rem;
    width: 50%;
}
