#sign-modal {
    width: 100vw;
    min-width: 500px;
    max-width: 1000px !important;
}

#sign-modal .panels-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

#sign-modal .panels-container > .canvas-panel {
    width: 600px;
    flex-shrink: 0;
}

#sign-modal .panels-container > .canvas-panel > div {
    width: 600px !important;
    height: 400px !important;
}

#sign-modal .panels-container > .right-panel {
    width: 100%;
    margin-left: 1.5em;
}

#sign-modal .panels-container > .right-panel.disabled {
    pointer-events: none;
    opacity: 0.5;
}

#sign-modal .panels-container > .right-panel button {
    width: 100%;
    margin: 0.33em 0;
}

#sign-modal .panels-container > .right-panel .spacer {
    margin: 1em 0;
}
